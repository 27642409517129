export const Actions = {
    INITIAL: 'INITIAL',
    MICROSOFT_CHECKOUT: 'MICROSOFT_CHECKOUT',
    SIGN_IN_WINDOWS_HELLO: 'SIGN_IN_WINDOWS_HELLO',
    SIGN_IN_WINDOWS_HELLO_FAILURE: 'SIGN_IN_WINDOWS_HELLO_FAILURE',
    INITIALIZE_PAYMENT: 'INITIALIZE_PAYMENT',
    CANCEL_ORDER: 'CANCEL_ORDER',
    PAYMENT_FAILURE: 'PAYMENT_FAILURE',
    PAYMENT_COMPLETE: 'PAYMENT_COMPLETE',
    NOTIFY_ORDER_COMPLETE: 'NOTIFY_ORDER_COMPLETE'
  };

  export const initialState = {
      initialState: false,
      showOrderDetails: false,
      paymentCompleted: false,
      orderPlaced: false,
  };

  export function CheckoutReducer(state, action) {

    switch (action.type) {
      
      case Actions.INITIAL: {
        return {
          ...state,
          initialState: true,
        };
      }

      case Actions.INITIALIZE_PAYMENT: {
        return {
          ...state,
          showOrderDetails: true,
        };
      }

      case Actions.CANCEL_ORDER: {
        return {
          ...initialState,
          showOrderDetails: false,
          paymentCompleted: false,
          orderPlaced: false,
        };
      }

      case Actions.PAYMENT_COMPLETE: {
        return {
          ...state,
          showOrderDetails: false,
          paymentCompleted: true,
        };
      }

      case Actions.NOTIFY_ORDER_COMPLETE: {
        return {
          ...state,
          paymentCompleted: false,
          orderPlaced: true,

        };
      }
  
      default:
        return state;
    }
  }