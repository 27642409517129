import { Dialog } from "@fluentui/react";
import { useCallback, useLayoutEffect, useState } from "react";
import OrderCompleteConfirmationLeft from "./order-complete-confirmation-left";
import OrderSummary from "./order-summary";
import { MsPay } from "../App.js";
import mslog from '../images/mslogo.PNG';

function OrderDialog(props) {

    const {
        isHide: showDialog,
        onCancelAction,
        onPlaceOrder,
        orderPlaced,
        isMerchantEndPage,
        opacity
      } = props;

    const [additionalCharge, setAdditionalCharge] = useState(0);
    const [canShow, setCanShow] = useState(false);

    const addAdditionalCharge = useCallback((amount) => {
        setAdditionalCharge(amount);   
    }, [setAdditionalCharge]);

    useLayoutEffect(() => {
        
        if((showDialog && !isMerchantEndPage)){
            setCanShow(true);
        }
    }, [showDialog, isMerchantEndPage, canShow]);

    let panelRightClass = orderPlaced && !MsPay() ? "order-dialog-panel-right order-placed-right alignright" : "order-dialog-panel-right alignright";
    let panelLeftClass = orderPlaced && !MsPay() ? "order-dialog-panel-left order-dialog-panel-left-margin alignleft" : "order-dialog-panel-left alignleft";
    let modalPropsClass = opacity ? "order-dlg half-opacity" : "order-dlg";

    return (
        <Dialog
            //hidden={!showDialog || isMerchantEndPage}
            hidden={!canShow}
            onDismiss={onCancelAction} 
            maxWidth="1800px"
            modalProps={{isBlocking: true, className: modalPropsClass, isDarkOverlay: true, overlay: {isDarkThemed: true} }}
        >
                <div className="order-dialog">
                    <div className={panelLeftClass}><OrderCompleteConfirmationLeft onCancelAction={onCancelAction} isOrderPlaced={orderPlaced} isMerchantEndPage={isMerchantEndPage} addAdditionalCharge={addAdditionalCharge} /></div>
                    <div className={panelRightClass}><OrderSummary onPlaceOrder={onPlaceOrder} isOrderPlaced={orderPlaced} isMerchantEndPage={isMerchantEndPage} additionalCharge={additionalCharge} /></div>
                </div>


                { MsPay() && 
                <div className="outsidedialog">
                    <div className="alignleft">
                        <label>Powered by <img src={mslog} alt="mslogo" /> <span className="wallet"> Wallet </span> </label>
                    </div>
                    <div className="alignright terms">
                        <a href="https://mscheckout1.microsoft.com">Privacy Policy</a>
                        <a href="https://mscheckout1.microsoft.com">Terms of Use</a>
                    </div>
                    <div className="clear"></div> 
                </div>}       
        </Dialog>
    )
}

export default OrderDialog;