// import logo from './logo.svg';
import React, { useCallback, useState }  from 'react';
import './MsPay.css';
//import './App.css';
import './Common.css'
// import CheckoutPanel from './checkout/checkout-panel';
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { IconButton } from '@fluentui/react';
import background from './images/header.png';
import { Actions } from './checkout/checkout-panel-reducer';
import { useCheckoutPanelReducer } from './checkout/use-checkout-panel-reducer';
import OrderDialog from './components/order-dialog';
import endPageImage from './images/endPage.PNG';

export function MSLogo(isMerchant) {
  return <div className="MS-Logo">
    <div className="MS-Logo-red" />
    <div className="MS-Logo-green" />
    <div className="MS-Logo-yellow" />
    <div className="MS-Logo-blue" />
  </div>;
}

export function MsPay() {
  return true;
}

function App() {
  initializeIcons();
  // const [openPanel, setOpenPanel] = useState(false);

  // function showPanel() {
  //   setOpenPanel(true);
  // }

  // function closePanel() {
  //   setOpenPanel(false);
  // }

  const { state, dispatch } = useCheckoutPanelReducer();

  const [startPage] = useState('merchant');
  const [windowshello, setWindowshello] = useState('false');
  const [opacity, setOpacity] = useState(false);
  const [endPage, setEndPage] = useState('');
  const [loader, setLoader] = useState(false);

  React.useEffect(() => {

    const params = new URLSearchParams(window.location.search);
    let page = params.get('startpage');
    console.log('page = '+page);
    //setStartPage(page);
    let wh = params.get('windowshello');
    setWindowshello(wh);
}, [startPage, endPage, windowshello]);

// React.useEffect(() => {
//   const timer = setTimeout(() => {
//     console.log('This will run after 1 second!')
//   }, 1000);
//   return () => clearTimeout(timer);
// }, []);

 
  const closeDialogAfter = useCallback(()=>{
    if(MsPay()) {
      setLoader(true);
      setOpacity(true);
      setTimeout(() => {
        console.log('This will run after 2 seconds!');
        setEndPage('merchant');  
        setTimeout(() => {
          setLoader(false); 
        }, 1000);
      }, 2000);

      // setTimeout(() => {
      //   console.log('This will run after 2 seconds!');
      //   setLoader(false);
      // }, 1000);

    } else {
      setTimeout(() => {
        console.log('This will run after 125 seconds!');
        setEndPage('merchant');
      }, 1000000);
    }
  }, [setEndPage, setOpacity]);

  const onCancelInitializePayment = useCallback(() => {
    
    if(state.orderPlaced) {
      setEndPage('merchant');
    } else {
      dispatch({ type: Actions.CANCEL_ORDER });
    }
    //setOpenPanel(false);
  }, [dispatch, setEndPage, state.orderPlaced]);

  const onPlaceOrder = useCallback(() => {
    closeDialogAfter();
    dispatch({ type: Actions.NOTIFY_ORDER_COMPLETE });
}, [dispatch, closeDialogAfter]);

  const useWindowsHello = useCallback(()=>{

    var publicKey = {
      // The challenge is produced by the server; see the Security Considerations
      challenge: new Uint8Array([21,31,105 /* 29 more random bytes generated by the server */]),
    
      // Relying Party:
      rp: {
        name: "MS Checkout Demo App with Hello PIN integration",
        icon: "https://mscheckout1.azurewebsites.net/"
      },
    
      // User:
      user: {
        id: Uint8Array.from(window.atob("MIIBkzCCATigAwIBAjCCAZMwggE4oAMCAQIwggGTMII="), c=>c.charCodeAt(0)),
        name: "tuweiqing@hotmail.com",
        displayName: "Weiqing Tu",
      },
    
      // This Relying Party will accept either an ES256 or RS256 credential, but
      // prefers an ES256 credential.
      pubKeyCredParams: [
        {
          type: "public-key",
          alg: -7 // "ES256" as registered in the IANA COSE Algorithms registry
        },
        {
          type: "public-key",
          alg: -257 // Value registered by this specification for "RS256"
        }
      ],
    
      authenticatorSelection: {
        //Select authenticators that support username-less flows
        requireResidentKey: true,
        //Select authenticators that have a second factor (e.g. PIN, Bio)
        userVerification: "required",
        //Selects between bound or detachable authenticators
        authenticatorAttachment: "platform"
    },
    
      //Since Edge shows UI, it is better to select larger timeout values
      timeout: 50000,
      //prevent re-registration by specifying existing credentials here
      excludeCredentials: [],
      //specifies whether you need an attestation statement
      attestation: "none"
    };
    setLoader(true);
    // Note: The following call will cause the authenticator to display UI.
    navigator.credentials.get({ publicKey })
      .then(function (newCredentialInfo) {
        console.log("[Hello Pin]::Validation Successful");
        dispatch({ type: Actions.INITIALIZE_PAYMENT });
        // Send new credential info to server for verification and registration.
        setLoader(false);
      }).catch(function (err) {
        console.log(err);
        // No acceptable authenticator or user refused consent. Handle appropriately.
        setLoader(false);
      });
  
  }, [dispatch]);

  const useWindowsHelloCreate = useCallback(()=>{

    var publicKey = {
      // The challenge is produced by the server; see the Security Considerations
      challenge: new Uint8Array([21,31,105 /* 29 more random bytes generated by the server */]),
    
      // Relying Party:
      rp: {
        name: "MS Checkout Demo App with Hello PIN integration",
        icon: "https://mscheckout1.azurewebsites.net/"
      },
    
      // User:
      user: {
        id: Uint8Array.from(window.atob("MIIBkzCCATigAwIBAjCCAZMwggE4oAMCAQIwggGTMII="), c=>c.charCodeAt(0)),
        name: "tuweiqing@hotmail.com",
        displayName: "Weiqing Tu",
      },
    
      // This Relying Party will accept either an ES256 or RS256 credential, but
      // prefers an ES256 credential.
      pubKeyCredParams: [
        {
          type: "public-key",
          alg: -7 // "ES256" as registered in the IANA COSE Algorithms registry
        },
        {
          type: "public-key",
          alg: -257 // Value registered by this specification for "RS256"
        }
      ],
    
      authenticatorSelection: {
        //Select authenticators that support username-less flows
        requireResidentKey: true,
        //Select authenticators that have a second factor (e.g. PIN, Bio)
        userVerification: "required",
        //Selects between bound or detachable authenticators
        authenticatorAttachment: "platform"
    },
    
      //Since Edge shows UI, it is better to select larger timeout values
      timeout: 50000,
      //prevent re-registration by specifying existing credentials here
      excludeCredentials: [],
      //specifies whether you need an attestation statement
      attestation: "none"
    };
    setLoader(true);
    // Note: The following call will cause the authenticator to display UI.
    navigator.credentials.create({ publicKey })
      .then(function (newCredentialInfo) {
        console.log("[Hello Pin]::Validation Successful");
        alert('WH registered');
        // Send new credential info to server for verification and registration.
        setLoader(false);
      }).catch(function (err) {
        console.log(err);
        // No acceptable authenticator or user refused consent. Handle appropriately.
        setLoader(false);
      });
  
  }, []);

  let isMerchantEndPage = (endPage === "merchant" && state.orderPlaced);
  
  return (
    <div className="main">
      <div class="loader" hidden={!loader}></div>
      <div hidden={!isMerchantEndPage}><img src={endPageImage} alt="endpage"/></div>
      
      <div className="Background" hidden={isMerchantEndPage}>
    <img src={background} alt="header" />
    <div className="checkout-btn-padding" hidden={MsPay()}>
      <div hidden={startPage !== "merchant"} className="merchant">
        <IconButton
          className="Checkout-button black"
        >
          <div className="Checkout-button-text Checkout-button-text-white">Checkout</div>
        </IconButton>
        <div className="pay-btn">
        <IconButton
          className="Checkout-button"
          onClick={useWindowsHello}
          onRenderIcon={MSLogo}
        >

          <div className="Checkout-button-text">Pay (Save $14)</div>
        </IconButton>
        </div>
        <div className="">
        <IconButton
          className="Checkout-button pay-pal-btn"
        >
          <div className="Checkout-button-text pay-pal"><span className="fontwt900">PayPal</span></div>
        </IconButton>
        </div>
      </div>

      <div hidden={startPage === "merchant"} className="pay-btn">
        <IconButton
          className="Checkout-button"
          onClick={useWindowsHello}
          onRenderIcon={MSLogo}
        >

          <div className="Checkout-button-text">Checkout with Microsoft Wallet</div>
          <span className="badge">Save $14</span>
        </IconButton>
      </div>
      <div hidden={windowshello !== "true"} className="pay-btn">
        <IconButton
          className="Checkout-button"
          onClick={useWindowsHelloCreate}
          onRenderIcon={MSLogo}
        >
          <div className="Checkout-button-text">Register Windows Hello</div>
        </IconButton>
      </div>

    </div>
    {/* <CheckoutPanel isPanelOpen={openPanel} onClosePanel={closePanel}></CheckoutPanel> */}
    <div className="checkout-btn-padding" hidden={!MsPay()}>
      <div hidden={startPage !== "merchant"} className="merchant">
        <IconButton
          className="Checkout-button black"
          onClick={useWindowsHello}
        >
          <div className="Checkout-button-text Checkout-button-text-white">CHECKOUT</div>
        </IconButton>
      </div>

      <div hidden={startPage === "merchant"} className="pay-btn">
        <IconButton
          className="Checkout-button"
          onClick={useWindowsHello}
          onRenderIcon={MSLogo}
        >

          <div className="Checkout-button-text">Checkout with Microsoft Wallet</div>
          <span className="badge">Save $14</span>
        </IconButton>
      </div>
      <div hidden={windowshello !== "true"} className="pay-btn">
        <IconButton
          className="Checkout-button"
          onClick={useWindowsHelloCreate}
          onRenderIcon={MSLogo}
        >
          <div className="Checkout-button-text">Register Windows Hello</div>
        </IconButton>
      </div>

    </div>
    <OrderDialog isHide={state.showOrderDetails} onCancelAction={onCancelInitializePayment} onPlaceOrder={onPlaceOrder} orderPlaced={state.orderPlaced} 
    isMerchantEndPage={false} opacity={opacity} />
  </div>

    </div>
  );
}

export default App;

