import { useEffect, useReducer } from "react";
import { Actions, CheckoutReducer, initialState } from "./checkout-panel-reducer";

export function useCheckoutPanelReducer() {
    const [state, dispatch] = useReducer(CheckoutReducer, initialState);
    
    useEffect(() =>{
        if(state.initialState){
            dispatch({type: Actions.CANCEL_ORDER});
        }
    }, [state.initialState]);

    return {state, dispatch};
}