import { IconButton } from "@fluentui/react";
import { MSLogo, MsPay } from "../App.js";
import shoe from "../images/shoe.png"
import themeshoe from '../images/theme-shoe.PNG'
import rebate from "../images/rebate.png"
import themerebate from "../images/theme-rebate.png"
import mslog from '../images/mslogo.PNG';

function OrderSummary(props) {

    let orderPlaced = false; //props.isOrderPlaced && !props.isMerchantEndPage;
    let shipping = "0.00";
    let totalCost = "63.00";
    let tax = "7.00";

    if (props.additionalCharge === 1) {
        shipping = "0.00";
        totalCost = "63.00";
        tax = "7.00";
    } else if (props.additionalCharge === 2) {
        shipping = "12.95";
        tax = "8.29";
        totalCost = "77.24";
    } else if (props.additionalCharge === 3) {
        shipping = "22.95";
        tax = "9.29";
        totalCost = "88.24";
    } else {
        shipping = "0.00";
        totalCost = "63.00";
    }

    let gradientText = MsPay() ? "" : "gradient-text";
    let gradientTextPadding = MsPay() ? "gradient-text-padding" : "gradient-text gradient-text-padding";

    return (
        <div className= {orderPlaced ? "order-dialog-panel" : "order-dialog-panel"}>
            <p hidden={orderPlaced} className="order-summary-title">Order Summary</p>
            <div className={orderPlaced ? "" : "order-summary-title2"}></div>
            <div hidden={!orderPlaced}>
                    <p className="order-summary-title">Order Details </p>
                    <p className="order-summary-title2">#10111011</p>
            </div>
            
            <div className='alignleft'>
                <img className="alignleft" src={MsPay() ? themeshoe : shoe} alt="shoe" />
                <p className="alignright title">RS Fast Veggies Men's Sneakers</p>
                <div className="clear" ></div>
            </div>
            <div className='alignright title-amount'>
                $70.00
            </div>
            <div className="clear" />
            <div className="separator coupon-separator" />
            
            {couponInformation()}

            <div className="more-font-ord-summary">
            <div className='alignleft'>
                <p>Subtotal</p> 
                <p>Shipping</p>
                <p>Tax</p>
                <p>Discounts/Coupons</p>
            </div>
            <div className='alignright txt-right'>
                <p> $70.00</p>
                <p>${shipping}</p>
                <p> ${tax}</p>
                <p>-$14.00</p>
            </div>
            <div className="clear" />
            <div className={gradientText}>
                <div className='alignleft gradient-text bold more-font'>Total</div>
                <div className='alignright gradient-text bold more-font'>${totalCost}</div>
                <div className="clear" />
            </div>
            </div>
            {
                MsPay() && 
                <div className="checkout-btn-padding-order" hidden={!MsPay() || orderPlaced}>
                <div className="merchant" hidden={orderPlaced}>
                <IconButton
                className="Checkout-button black"
                onClick={props.onPlaceOrder}
              >
                <b>PAY ${totalCost}</b>
              </IconButton>
              </div>
              </div>
            }
            
            { 
              !MsPay() && <div hidden={orderPlaced} className="pay-btn" >
            <IconButton 
                className="pay-button" 
                onClick={props.onPlaceOrder}
                onRenderIcon={MSLogo}
                >
                <div className="Checkout-button-text">Pay ${totalCost}</div>
            </IconButton>
            </div>
            }

            <p className={gradientTextPadding} hidden={!orderPlaced}>This receipt has been added to your<a href="https://www.microsoft.com"> <img className="" src={mslog} alt="mslogo" /></a><a href="https://mscheckout1.azurewebsites.net">Wallet</a>.</p>
        </div>
    );

    function couponInformation() {

        if(true) {
            return <div hidden={orderPlaced} className="ors">
            <div className='alignleft order-summary-coupon promo-margin'>
                <label className="gradient-text"> Promotions </label>
                {/* <span className="gradient-text">
                    20% off of a single item
                </span> */}
            </div>
            <div className='alignright promo-margin'>
                20% off of a single item
            </div>
            <div className="clear" />
            <div className="separator" />
            
            <div className='alignleft order-summary-coupon'>
                <label className="gradient-text"> +Discounts </label>             
            </div>
            <div className="clear" />
            <div className="separator" />

            <div className='alignleft order-summary-coupon'>
                <label className="gradient-text"> +Gift/E-Gift/Store credit </label>
            </div>
            <div className="clear" />
            <div className="separator" />
        </div>
        }

        return <div hidden={orderPlaced} className="ors">
            <div className='alignleft order-summary-coupon'>
                <label> Coupons </label>
                <span className="gradient-text">
                    We found the best coupon for your purchase!
                </span>
                <label></label>
                <span><p>20% OFF from retailmenot</p></span>
                <label>Bing Rebates</label><span className="extra-margin gradient-text">You’re getting 6% cashback on this purchase!</span>
            </div>
            <div className='alignright'>
                <img className="alignleft" height="75px" src={MsPay() ? themerebate : rebate} alt="rebate" />
            </div>
            <div className="clear" />
            <div className="separator" />
        </div>;
    }
}

export default OrderSummary;