import React, { useCallback, useState }  from 'react';
import { DefaultButton, FontIcon, IconButton, OverflowSet } from "@fluentui/react";
import visa from '../images/visa.png';
import apay from '../images/afterpay.png';
import mslog from '../images/mslogo.PNG';
import { MsPay } from "../App.js";

function OrderCompleteConfirmationLeft(props) {

    const {
        onCancelAction,
        isOrderPlaced,
        isMerchantEndPage,
        addAdditionalCharge,
      } = props;

    const orderConfirmation = <div className="order-comp">
        <p className="confirmation-page-header gradient-text2">Woohoo!</p>
        <p>Your order is complete and on its way</p>
        <p className="gradient-text">With Microsoft <a href="https://www.microsoft.com"> <img className="" src={mslog} alt="mslogo" /></a> <a href="https://mscheckout1.azurewebsites.net">Wallet</a> you saved <span className="currency"><b>$18.20</b></span> on this purchase!</p>
        <ul>
            <li><span className="currency">$14.00</span> with coupons</li>
            <li><span className="currency">$4.2</span> with <a href="https://mscheckout1.azurewebsites.net">Bing Rebates</a></li>
        </ul>
        <p className="dont-forget gradient-text">Don't forget to check with your credit card for the <b>3% cashback</b> on this purchase.</p>
        <p className="confirmation-page-text2">This year you <b className="currency">saved $253</b> with Microsoft <a href="https://www.microsoft.com"> <img className="" src={mslog} alt="mslogo" /></a> <a href="https://mscheckout1.azurewebsites.net">Wallet</a></p>
            <div className="confirmation-page-back">
                <DefaultButton className="confirmation-page-back-btn border-gradient border-gradient-purple" ic onClick={onCancelAction}>Back to Puma homepage</DefaultButton>
            </div>
    </div>;

    const [addressOption, setAddressOption] = useState(1);

    const setOption = useCallback((index) =>{
        console.log('address option selected');
        setAddressOption(index);
    }, [setAddressOption])

    const [deliveryOption, setDeliveryOption] = useState(1);

    const delOption = useCallback((index) =>{
        console.log('address option selected');
        setDeliveryOption(index);
        addAdditionalCharge(index);
    }, [setDeliveryOption, addAdditionalCharge])

    const onRenderOverflowButton = (overflowItems) => {
        return <IconButton
        role="menuitem"
        title="More options"
        menuIconProps={{ iconName: 'ChevronDown' }}
        menuProps={{ items: overflowItems }}
    />;
    }
    

    if(!MsPay() && isOrderPlaced && !isMerchantEndPage) {
        return orderConfirmation;
    }

    return <div className="flex-container">
    <div className="completed-icon gradient-text">
        <FontIcon aria-label="Completed" iconName="Completed" />
    </div>
    <div className="flex-container">
        <div className="recipient-information">
            {switchAddress(addressOption)}
                <div className="alignright">

                <OverflowSet
                        aria-label="Basic Menu Example"
                        role="menubar"

                        overflowItems={addressOverflowItems()}
                        onRenderOverflowButton={onRenderOverflowButton}
                        overflowSide={'start'}
                    />
                </div>
        </div>
    </div>
    <div className="completed-icon gradient-text">
        <FontIcon aria-label="Completed" iconName="Completed" />
    </div>
    <div className="recipient-information delivery-height">
    {switchDelivery(deliveryOption)}
    <div className="alignright">
    <OverflowSet
                        aria-label="Basic Menu Example"
                        role="menubar"
                        className="delivery-drop"
                        overflowItems={deliveryOptionItems()}
                        onRenderOverflowButton={onRenderOverflowButton}
                        overflowSide={'start'}
                    />
    </div>
    </div>
    <div className="completed-icon gradient-text">
        <FontIcon aria-label="Completed" iconName="Completed" />
    </div>
    <div className="recipient-information recipient-payment">
    <div className="alignleft">
        <div className="type">Payment method</div>
        <div className="details"><img className="alignleft visa" src={visa} alt="visa" /> **** 5545 (Shopping)</div>
        <div className="gradient-text cash-back">Earn 3% cashback on this card</div>
        <div className="details pay-details">More payment options 
            <img className="alignleft" src={apay} alt="apay" />
        </div>
    </div>
        <div className="alignright chvdown">
        <IconButton
            role="menuitem"
            title="More options"
            className="chvdown2"
            menuIconProps={{ iconName: 'ChevronDown' }}
        />
    </div>
    </div>
</div>;

    function deliveryOptionItems() {

        return [
            {
                key: '1',
                name: deliveryOption === 1 ? <b>Standard (5-7 business days): FREE</b> : 'Standard (5-7 business days): FREE',
                onClick: () => delOption(1),
                className: 'del-drop'
            },
            {
                key: '2',
                name: deliveryOption === 2 ? <b>Express (2-5 business days): $12.95</b> : 'Express (2-5 business days): $12.95',
                onClick: () => delOption(2),
                className: 'del-drop'
            },
            {
                key: '3',
                name: deliveryOption === 3 ? <b className="redu-font">Next day (1-2 business days): $22.95</b> : 'Next day (1-2 business days): $22.95',
                onClick: () => delOption(3),
                className: 'del-drop',
            },
            {
                key: '4',
                name: <div className="additional-ship">Ship to a store 8.4 miles away<FontIcon className="rightChevron" aria-label="ChevronRight" iconName="CaretSolidRight" /></div>,
                onClick: () => delOption(100),
                className: 'del-drop del-drop2',
                iconName: 'ChevronDown'
            },
        ];
    }

    function addressOverflowItems() {
        let address1 = <div className="addresses">
                            <p className="drp-dwn-name">Weiqing Tu</p>
                            <p>One Microsoft Way, Redmond, WA 98052</p> 
                            <p>tuweiqing@hotmail.com</p> 
                            <p>(123) 432-5678</p>
                            </div>;
        
        let address2 = <div className="addresses">
                            <p className="drp-dwn-name">Joe Smith</p>
                            <p>2912 Colonial Dr, Houston, TX 77032</p> 
                            <p>Joesmith@outlook.com</p> 
                            <p>(123) 123-5667</p>
                            </div>;

        let address3 = <div className="addresses">
                            <p className="drp-dwn-name">Ajay Pinari</p>
                            <p>3402 Union Rd, New York, NY 10003</p> 
                            <p>Ajaypinari@outlook.com</p> 
                            <p>(123) 432-5678</p>
                            </div>;
        let addNewAdd = <div className="addresses"><p><FontIcon className="add-new-ship-icon" aria-label="Add" iconName="Add" />Add new shipping Address</p></div>;
        return [
            {
                key: '1',
                name: address1,
                onClick: () => setOption(1),
            },
            {
                key: '2',
                name: address2,
                onClick: () => setOption(2),
            },
            {
                key: '3',
                name: address3,
                onClick: () => setOption(3),
            },
            {
                key: '4',
                name: addNewAdd,
                className: 'material-icons newaddress',
                icon: 'Add',
                disabled: true,
            },
        ];
    }

    function switchDelivery(id) {
        switch(id){
            case 1:
                return <div className="alignleft">
                            <div className="type">Delivery</div>
                            <div className="details">Standard (5-7 business days): FREE</div>
                        </div>;
            case 2:
                    return <div className="alignleft">
                                <div className="type">Delivery</div>
                                <div className="details">Express (2-5 business days): $12.95</div>
                            </div>;
            case 3:
                return <div className="alignleft">
                            <div className="type">Delivery</div>
                                <div className="details">Next day (1-2 business days): $22.95</div>
                        </div>;
            default:
                break;
        }
        return <div className="alignleft">
            <div className="type">Delivery</div>
            <div className="details">Standard (5-7 business days)</div>
        </div>;
    }

    function switchAddress(id) {
        switch(id){
            case 1:
                return <div className="alignleft">
                        <div className="type">Shipping information</div>
                        <div className="details">Weiqing Tu</div>
                        <div className="details">One Microsoft Way, Redmond, WA 98052</div>
                        <div className="details">tuweiqing@hotmail.com</div>
                        <div className="details">(123) 432-5678</div>
                    </div>;

            case 2:
                return <div className="alignleft">
                        <div className="type">Shipping information</div>
                        <div className="details">Joe Smith, 657 Airport Road, </div>
                        <div className="details">Colonial Dr, Houston, Houston, TX 77032</div>
                        <div className="details">Joesmith@outlook.com</div>
                        <div className="details">(123) 123-5667</div>
                    </div>;
            
            case 3:
                return <div className="alignleft">
                        <div className="type">Shipping information</div>
                        <div className="details">Ajay Pinari,</div>
                        <div className="details">3402 Union Rd, New York, NY 10003</div>
                        <div className="details">Ajaypinari@outlook.com</div>
                        <div className="details">(123) 789-7897</div>
                    </div>;

        default:
            break;
        }
        return <div className="alignleft">
            <div className="type">Shipping information</div>
            <div className="details">John Smith</div>
            <div className="details">33 Irving Pl, New York, NY 10003</div>
            <div className="details">Johnsmith@outlook.com</div>
            <div className="details">(123) 432- 5678</div>
        </div>;
    }
};

export default OrderCompleteConfirmationLeft;

